import React, { useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/pro-regular-svg-icons";

// Fonction pour insérer une image via URL
function insertImageByUrl(quillRef) {
    if (quillRef.current) {
        const quill = quillRef.current.getEditor(); // Accéder à l'instance de Quill
        const url = prompt("Entrez l'URL de l'image");
        if (url) {
            const range = quill.getSelection(); // Obtenir la position du curseur
            if (range) {
                quill.insertEmbed(range.index, 'image', url, 'user'); // Insérer l'image à l'emplacement du curseur
            }
        }
    }
}

// Fonction pour insérer une image depuis l'ordinateur
function insertImageFromFile(quillRef) {
    if (quillRef.current) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = async (event) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const quill = quillRef.current.getEditor();
                    const range = quill.getSelection();
                    if (range) {
                        quill.insertEmbed(range.index, 'image', reader.result, 'user');
                    }
                };
                reader.readAsDataURL(file); // Lire le fichier comme URL de données
            }
        };

        input.click(); // Ouvrir le sélecteur de fichiers
    }
}

// Créer un ID unique pour chaque barre d'outils en fonction de la langue
const generateToolbarId = (lang) => `toolbar-${lang}`;

const CustomToolbar = ({ lang, quillRef }) => (
    <div id={generateToolbarId(lang)}>
        {/* Titres */}
        <select className="ql-header">
            <option value="1">Titre 1</option>
            <option value="2">Titre 2</option>
            <option value="3">Titre 3</option>
            <option value="4">Titre 4</option>
            <option value="">Normal</option>
        </select>
        <span className="toolbar-divider"></span>

        {/* Boutons de format de texte */}
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <button className="ql-strike"></button>
        <span className="toolbar-divider"></span>

        {/* Listes */}
        <select className="ql-align" />{/* Alignement */}
        <button className="ql-list" value="ordered"></button> {/* Liste numérotée */}
        <button className="ql-list" value="bullet"></button> {/* Liste à puces */}
        <span className="toolbar-divider"></span>

        <select className="ql-color" />
        <select className="ql-background" />
        <span className="toolbar-divider"></span>

        <button className="ql-script" value="sub"></button>
        <button className="ql-script" value="super"></button>

        {/* Citations */}
        <button className="ql-blockquote"></button>
        <span className="toolbar-divider"></span>

        {/* Liens */}
        <button className="ql-link"></button>
        {/* Insertion d'images via PC */}
        <button className="custom-btn-file" onClick={() => insertImageFromFile(quillRef)} type="button">
            <FontAwesomeIcon icon={faImage} /> {/* Utilisez une icône différente si nécessaire */}
        </button>
        {/* Insertion d'images via URL */}
        <button className="custom-btn-img" onClick={() => insertImageByUrl(quillRef)} type="button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 149.3c-15.3-6.6-31.4-11.7-48-15.3l0-134c0-8.8-7.2-16-16-16L64 80c-8.8 0-16 7.2-16 16l0 320 0 .3 4.5-6.2 80-112C137 291.7 144.2 288 152 288s15 3.7 19.5 10L202 340.7l83-107.4c4.6-5.9 11.6-9.3 19-9.3s14.5 3.4 19 9.3l29.6 38.3c-.4 2.7-.6 5.5-.6 8.4c0 2.7 .2 5.4 .6 8l-.6 0 0 192L64 480c-35.3 0-64-28.7-64-64L0 96zm102.4 56c8.6-14.9 24.4-24 41.6-24s33 9.1 41.6 24s8.6 33.1 0 48s-24.4 24-41.6 24s-33-9.1-41.6-24s-8.6-33.1 0-48zM384 280c0-13.3 10.7-24 24-24c128.1 0 232 103.9 232 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zm0 104c0-17.7 14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32s-32-14.3-32-32z"/></svg>
        </button>
        <span className="toolbar-divider"></span>


        <button className="ql-clean" />
    </div>
);

const TextEditor = ({ lang, content, handleEditorChange, style }) => {
    const quillRef = useRef(null); // Créer une ref pour chaque éditeur

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.focus(); // S'assurer que l'éditeur est bien focus avant d'interagir
        }
    }, [quillRef]);

    const modules = {
        toolbar: {
            container: `#${generateToolbarId(lang)}`, // Utiliser l'ID unique pour la barre d'outils
        },
    };

    return (
        <>
            <CustomToolbar lang={lang} quillRef={quillRef} /> {/* Barre d'outils spécifique à chaque langue */}
            <ReactQuill
                ref={quillRef} // Associer la ref à ReactQuill
                theme="snow"
                style={style}
                value={content}
                modules={modules}
                onChange={(value) => handleEditorChange(lang, value)}
            />
        </>
    );
};

export default TextEditor;
