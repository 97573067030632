import Axios from "./caller.service";
//import axios from "axios";
/*const Axios = axios.create({
    //baseURL: 'https://5g18qllika.sharedwithexpose.com/api/',
    baseURL: 'http://localhost:3002',
})*/
//system/getcommunications
let getAllCommunications = async () => {
    const {data} = await Axios.get('/system/communications')
    return data
}
let getAllTags = async () => {
    const {data} = await Axios.get('/tags')
    return data
}
let getAllCountries = async () => {
    const {data} = await Axios.get('/countries')
    return data
}
let getAllLanguages = async () => {
    const {data} = await Axios.get('/languages')
    return data
}
let getCommunication = (id) => {
    return Axios.get('/system/communication/'+id)
}

let addCommunication = (communication) => {
    console.log('addCommunication', communication)
    return Axios.post(`/system/communications`, communication)
}

let updateCommunication = (id, communication) => {
    return Axios.put(`system/communication/${id}`, communication)
}

let deleteCommunication = (id) => {
    return Axios.delete(`system/communication/${id}`)
}

// Fonction pour vérifier si une communication en ligne existe pour une catégorie donnée
let checkOnlineCommunicationExists = async (category) => {
    const allCommunications = await getAllCommunications();
    return allCommunications.some(communication => communication.status === 'online' && communication.category === category);
};

let getOnlineCommunication = async (category) => {
    const allCommunications = await getAllCommunications();
    return allCommunications.find(communication => communication.status === 'online' && communication.category === category);
};

let getLastCommunicationId = async () => {
            const allCommunications = await getAllCommunications();
    let lastCommunication = allCommunications[allCommunications.length - 1];
    return lastCommunication.id;
}

export const communicationsService = {
    checkOnlineCommunicationExists,
    getLastCommunicationId,
    getAllTags,
    getAllCountries,
    getAllLanguages,
    getOnlineCommunication,
    getAllCommunications,
    getCommunication,
    addCommunication,
    updateCommunication,
    deleteCommunication
}